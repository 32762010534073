import { Container } from '@mui/material';
import React, { useEffect } from 'react';
// import ReactGA from 'react-ga4';
import LinkCard from '../../components/LinkCard';
import TitleBar from '../../components/TitleBar';
import logoRound from '../../images/gifs/Portfolio_LearningMachine.gif';
import jakaBlack from '../../images/jaka-black.svg';
import credilikeme from '../../images/logos_portfolio/credilikeme.svg';
import fivehundred from '../../images/logos_portfolio/JakaLogos_500.png';
import finerio from '../../images/logos_portfolio/JakaLogos_FinerioConnect.png';
import fondeadora from '../../images/logos_portfolio/JakaLogos_fondeadora.png';
import prescrypto from '../../images/logos_portfolio/JakaLogos_prescrypto.png';
import sixtant from '../../images/logos_portfolio/JakaLogos_sixtant.png';
import trebel from '../../images/logos_portfolio/JakaLogos_trebel.png';
import wowdao from '../../images/logos_portfolio/JakaLogos_wowdao.png';
import zenda from '../../images/logos_portfolio/JakaLogos_zndl.png';
import kobra from '../../images/logos_portfolio/kobra.svg';
import NeefterLogo from '../../images/logos_portfolio/NeefterLogo.png';
import RiviumLogo from '../../images/logos_portfolio/RiviumLogo.png';
import SuarmiLogo from '../../images/logos_portfolio/SuarmiLogo.png';
import Kuadra from '../../images/logos_portfolio/Kuadra.png';
import { Flex, Header } from './styles';

function Portfolio() {
  // useEffect(() => {
  //   // Initialize Google Analytics
  //   ReactGA.pageview('/portfolio', undefined, 'Portafolio');
  // }, []);

  return (
    <div>
      <Container>
        <Header>
          <img alt="logo" src={jakaBlack} />

          <div>PORTFOLIO</div>
        </Header>

        <TitleBar title="ENTREPRENEUR:" />

        <Flex>
          <LinkCard
            url="https://credilike.me"
            image={credilikeme}
            text="Gamified Personal Loan Experience. BNPL For the win"
          />

          <LinkCard
            url="https://kobra.red"
            image={kobra}
            text="Network of on-demand field-agents for Fintechs"
          />

        </Flex>

        <TitleBar title="INVESTMENTS:" />

        <Flex>
          <LinkCard
            url="https://zenda.la/"
            bgColor="#dadada"
            image={zenda}
            text="Free health insurance for everybody"
          />

          <LinkCard
            url="https://home.trebel.io/"
            image={trebel}
            text="The world's only premium music service specifically designed for people who can't afford a subscription plan. All paid for by brands"
          />

          <LinkCard
            url="https://www.sixtant.io/"
            bgColor="#dadada"
            image={sixtant}
            text="Market-Neutral algorithmic crypto trading firm"
          />

          <LinkCard
            url="https://fondeadora.com/"
            image={fondeadora}
            text="Mexican fastest growing Neo-Bank"
          />

          <LinkCard
            url="https://wowdao.com/"
            image={wowdao}
            text="On-chain drawings & giveaways for the most valuable NFTs in crypto"
          />

          <LinkCard
            url="https://www.prescrypto.com/"
            image={prescrypto}
            text="Generate and manage medical prescriptions"
          />

          <LinkCard
            url="https://suarmi.com/index"
            image={SuarmiLogo}
            text="The easiest Fiat Ramp for your niche tokens"
          />

          <LinkCard
            url="https://www.neefter.com/"
            image={NeefterLogo}
            text="Launch your NFT collection, easy"
          />

          <LinkCard
            url="https://rivium.mx/"
            image={RiviumLogo}
            text="Automates your long-term investing while you live creatively"
          />

          <LinkCard
            url="https://kuadra.com.co/"
            image={Kuadra}
            text="A comprehensive tool for efficient, accessible business management, anytime, anywhere"
          />

        </Flex>

        <TitleBar title="FUNDS:" />

        <Flex>
          <LinkCard
            columns={2}
            url="https://latam.500.co/"
            image={fivehundred}
            text="The most active seed stage fund in the world"
          />

          <LinkCard
            columns={2}
            url="https://www.sixtant.io/"
            bgColor="#dadada"
            image={sixtant}
            text="Market-Neutral algorithmic crypto trading firm"
          />
        </Flex>

        <TitleBar title="EXITS: " />

        <Flex>
          <LinkCard
            url="https://finerioconnect.com/"
            image={finerio}
            text="The most complete, secure and robust open banking API in Latin America"
          />
        </Flex>

      </Container>

      <img
        alt="logo"
        src={logoRound}
        style={{ marginTop: '3rem', marginBottom: -7 }}
        width="100%"
      />
    </div>
  );
}

export default Portfolio;
