// import { Container } from '@mui/material';
import React,{ useRef, useState, useEffect, Redirect} from 'react';
import ReactDOM from "react-dom";
// import ReactGA from 'react-ga4';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import soundOn from '../../images/soundon.png';
import soundOff from '../../images/soundoff.jpeg';
import FintechCreatorVideo from '../../assets/video/FintechCreator.mp4'


function FintechCreatorHome() {

  // useEffect(() => {
  //   window.location.assign('https://ogfintecreaator.my.canva.site/og-fintech-creator')
  // }, []); // this will run only once when the component is mounted

    // useEffect(() => {
    //   // window.location.href = 'https://ogfintecreaator.my.canva.site/og-fintech-creator';
    //   // window.location = "https://ogfintecreaator.my.canva.site/og-fintech-creator"
    //   // return false; 
    // }, []);

  // return (
  //   <div>
      {/* <Iframe iframe={iframe} /> */}
    {/* </div>
  ); */}

 


  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    // ReactGA.pageview('/fintech-creator', undefined, 'FintechCreatorHome');
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  const [muted, setMuted] = React.useState(true);

  const toggleMute = () => {
    setMuted(prevMuted => !prevMuted);
  }

  const [isMuted, setIsMuted] = useState(false);
  const videoRef = React.useRef(null);

  function handleVideoClick() {
    setIsMuted(prevIsMuted => !prevIsMuted);
    videoRef.current.muted = !videoRef.current.muted;
  }
  


  return (
 
    <div style={{backgroundColor: 'black'}}>

       
      <Container>
      <br />
        <Row>
          <Col lg={2}>
          </Col>
          <Col md={12} lg={4} style={{textAlign:"center", color:"white"}}>
              {windowSize.innerWidth >= 992 ? ( <div><br /><br /><br /><br /><br /><br /><br /><br /><br /></div>): (<div></div>)}
              <p>DO YOU HAVE WHAT IT TAKES TO BE AN
              <h1>OG FINTECH CREATOR</h1></p>
              <Button variant="outline-light" href="https://ogfintecreaator.my.canva.site/og-fintech-creator" size="lg"><b>MORE INFO</b></Button>
          </Col>
          <Col md={12} lg={4} style={{textAlign:"center"}}>
        
          <Button variant="white" onClick={toggleMute}> 
              {muted ? <img src={soundOn} alt="logo" width="45px" height="45px"/> : <img src={soundOff} alt="logo" width="45px" height="45px"/>}
          </Button> <br/>
     
            <video
              onClick={handleVideoClick}
              autoPlay={true}
              controls={false}
              controlsList="nodownload nofullscreen noremoteplayback"
              loop={true}
              muted={muted}
              playsInline={true}
              style={{ pointerEvents: 'none' }}
              width="100%"
              >
              <source src={FintechCreatorVideo} type="video/mp4"/>
            </video> 

          </Col>
        </Row>
      <br />

      </Container>
     
      {/* <embed src={"https://bafkreiapiydtahwyvmlqvtfe5phjihpcylljtqyaixzyvxykjkd5qwtn4m.ipfs.nftstorage.link/#toolbar=0"}  width="100%" height="600px"/> */}
      


    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<FintechCreatorHome />, rootElement);
export default FintechCreatorHome;
