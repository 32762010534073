import { Image } from '@chakra-ui/react';
import { Box, Stack, Typography, Link } from '@mui/material';
import React from 'react';
// import { Link } from 'react-router-dom';
import jakaBlack from '../../../../images/jaka-labs-logo.png';
import SideMenu from '../SideMenu';

function Navbar() {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={{ xs: 'space-between', lg: 'center' }}
      style={{ backgroundColor: 'black' }}
      py="10px"
      w="100%">
      <Box></Box>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent={{ xs: 'center', lg: 'flex-start' }}
        spacing="25px"
        px={{ xs: 2, lg: 0 }}>
        <Box as={Link} to="/">
          <Image alt="logo" filter="invert(1)" src={jakaBlack} w="100px" />
        </Box>

        <Stack
          display={{ xs: 'none', lg: 'flex' }}
          direction="row"
          alignItems="center"
          spacing="25px">


          <Typography         
            color="#fff"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase">      
            <Link  href="https://www.jaka.art/fintech-creator" rel="noreferrer"  style={{ textDecoration: 'none', color: 'white' }}>
                  HOME
            </Link>
          </Typography>

          <Typography         
            color="#fff"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase">      
            <Link  href="https://www.jaka.art/fintech-creator-og/1" rel="noreferrer"  style={{ textDecoration: 'none', color: 'white' }}>
                  WHY
            </Link>
          </Typography>


          <Typography         
            color="#fff"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase">      
            <Link  href="https://www.jaka.art/fintech-creator-og/2" rel="noreferrer"  style={{ textDecoration: 'none', color: 'white' }}>
                  ABOUT
            </Link>
          </Typography>

          <Typography         
            color="#fff"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase">      
            <Link  href="https://www.jaka.art/fintech-creator-og/3" rel="noreferrer"  style={{ textDecoration: 'none', color: 'white' }}>
                  UTILITY
            </Link>
          </Typography>


          <Typography         
            color="#fff"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase">      
            <Link  href="https://www.jaka.art/fintech-creator-og/4" rel="noreferrer"  style={{ textDecoration: 'none', color: 'white' }}>
                  NOMITATIONS
            </Link>
          </Typography>

          
          <Typography         
            color="#fff"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase">      
            <Link  href="https://www.jaka.art/fintech-creator-og/5" rel="noreferrer"  style={{ textDecoration: 'none', color: 'white' }}>
                  APPLY
            </Link>
          </Typography>

        </Stack>
      </Stack>

      <Box display={{ xs: 'block', lg: 'none' }}>
        <SideMenu />
      </Box>
    </Stack>
  );
}

export default Navbar;
