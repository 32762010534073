import { useEffect, useState } from 'react';
// import ReactGA from 'react-ga4';
import Container from 'react-bootstrap/Container';
import Feed from '../../components/Feed'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import { FaArrowLeft } from 'react-icons/fa';

import { Div } from './styles';
import Banner from '../../components/Banner';

const FeedsPage = () => {

  const API = process.env.REACT_APP_API_URL;
  let data = [
    {
      "_id": "63dbfc8d7d5a928b034a8823",
      "description": "Inspired by TonyRobbins’ book “Money”, we simulated the analysis of the performance of investment funds in the Mexican wealth management sector. \nThis is a report on our findings (en español): https://bit.ly/3S8UjOE",
      "images": [],
      "title": "Research: Exceso de Comisiones"
    },
    {
      "_id": "63dbfcee7d5a928b034a8824",
      "description": "En el primer meetup IRL invitamos a Felipe Servin de Neefter, para compartir sus aprendizajes de comunidades digitales y NFTs. \nEl keynote se convirtió en un círculo de reflexión grupal donde todos compartimos puntos de vista.\n",
      "images": [
        "https://twitter.com/jaka_labs/status/1573776689284481025?s=20&t=gobH6xsW-pf2FfzJjXtxTQ"
      ],
      "title": "Meetup IRL #1"
    },
    {
      "_id": "63dbfd3117d218ebab62f34c",
      "description": "Lanzamos el OG Founders pass, un NFT dirigido a los emprendedores del portafolio JAKA. \nhttps://jakalabs.substack.com/p/og-founders-pass-memebresia-nft-para",
      "images": [
        "https://jakalabs.substack.com/p/og-founders-pass-memebresia-nft-para"
      ],
      "title": "OG Founders Pass"
    },
    {
      "_id": "63dbfd9e7d5a928b034a8825",
      "description": "Estuvimos presentes en Blockchain Land, la conferencia sobre Blockchain más grande en Monterrey. \nArmando Kuroda participó en un panel con OGs de la industria ",
      "images": [
        "https://twitter.com/jaka_labs/status/1577775740505837568?s=20&t=gobH6xsW-pf2FfzJjXtxTQ"
      ],
      "title": "Blockchain Land Monterrey"
    },
    {
      "_id": "63dc02a917d218ebab62f34d",
      "description": "Lee la guía para entender las implicaciones de los NFTs en Propiedad Intelectual de archivos digitales. ",
      "images": [
        "https://jakalabs.substack.com/p/propiedad-intelectual-y-nft"
      ],
      "title": "Propiedad Intelectual y NFTs"
    },
    {
      "_id": "63dc04fd17d218ebab62f34e",
      "description": "Organizamos un taller de creación y automatización de DAOs con EVMCrispr, impartido por Sem Brestels de @blossom_labs, developer OG de sistemas de votaciones en DAOs. \n",
      "images": [
        "https://twitter.com/jaka_labs/status/1598119083873968128?s=20&t=gobH6xsW-pf2FfzJjXtxTQ"
      ],
      "title": "Taller de DAOs"
    },
    {
      "_id": "63dc05367d5a928b034a8826",
      "description": "Antes de terminar el 2022 publicamos la primera edición del #Mapaweb3 de LatAm. Conoce las 291 comunidades y proyectos web3 protagonistas de esta nueva ola tecnológica. ",
      "images": [
        "https://twitter.com/jaka_labs/status/1600248845589393408?s=20&t=gobH6xsW-pf2FfzJjXtxTQ"
      ],
      "title": "Mapa web3 LatAm 2022"
    },
    {
      "_id": "63dc05b517d218ebab62f34f",
      "description": "Zenda.la es una suite de soluciones digitales de salud que hace accesibles los seguros médicos en México. \nPlaticamos con Diego Muradás, su co-fundador, y publicamos un long-form blog post para entender el status quo de la innovación en seguros de salud en México. ",
      "images": [
        "https://jakalabs.substack.com/p/diego-muradas-zendala"
      ],
      "title": "Portfolio Blog: Zendala"
    },
    {
      "_id": "63dc05fc17d218ebab62f350",
      "description": "El Arte y las Finanzas están en nuestro ADN, por lo que cerramos el 2022 con el último meetup IRL sobre Valuación de Arte, donde Joel Escobar nos compartió sus experiencias asignando valor financiero a obra artística. ",
      "images": [
        "https://twitter.com/jaka_labs/status/1603193338949050368?s=20&t=gobH6xsW-pf2FfzJjXtxTQ"
      ],
      "title": "Meetup IRL #5: Valuación del Arte"
    },
    {
      "_id": "63dc067117d218ebab62f351",
      "description": "Platicamos con el Dr. Everardo J. Barojas, founder y CEO de Prescrypto, la primera plataforma mexicana de e-prescription construida con tecnología blockchain. ",
      "images": [
        "https://jakalabs.substack.com/p/prescrypto-la-receta-medica-digital"
      ],
      "title": "Portfolio Views: Prescrypto"
    },
    {
      "_id": "647fabde707089084f8ba727",
      "description": "En este artículo compartimos los aprendizajes del proceso de registrar a jaka_labs como una DAO reconocida por las autoridades del estado de Wyoming en USA.\nhttps://jakalabs.substack.com/p/jaka_labs-es-una-dao",
      "images": [],
      "title": "¡jaka_labs es una DAO!"
    },
    {
      "_id": "647fac15707089084f8ba728",
      "description": "Bright Moments es una galería de arte NFT organizada como DAO. Su colección principal de NFT es CryptoCitizens; 10 000 NFT únicos con un mint de 1000 en diferentes ciudades del mundo.\n\nhttps://jakalabs.substack.com/p/why-we-love-bright-moments",
      "images": [],
      "title": "Why we love Bright Moments"
    },
    {
      "_id": "647fac5b707089084f8ba729",
      "description": "Acompañamos a ArtePro en su proceso de inmersión a web3, en colaboración con Operator. En esta nota describimos el experimento pionero que combina Filantropía, Arte y comunidades tokenizadas.\n\nhttps://jakalabs.substack.com/p/artepro3-filantropia-arte-y-blockchain",
      "images": [],
      "title": "ArtePro3: Filantropía, Arte y Blockchain"
    },
    {
      "_id": "647fac84707089084f8ba72a",
      "description": "Platicamos con el equipo fundador de Rivium, una nueva app móvil de micro inversión automatizada que facilita la inversión a largo plazo. Esto fue lo que aprendimos.\n\nhttps://jakalabs.substack.com/p/rivium-app-para-la-nueva-generacion",
      "images": [],
      "title": "Rivium: app para la nueva generación de inversionistas"
    },
    {
      "_id": "647facc6707089084f8ba72b",
      "description": "Hace poco tuvimos la oportunidad de asistir en vivo al evento de Bright Moments Tokio, y ahora entendemos por qué esta plataforma de arte NFT ha capturado los corazones de tantos. Desde la icónica intersección de Shibuya hasta el edificio Parco y una tradicional casa japonesa, Bright Moments transformó Tokio en un verdadero paraíso del arte digital.\n\nhttps://jakalabs.substack.com/p/why-we-love-bright-moments-tokyo",
      "images": [
        "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fd80a3e5a-5b0f-4b40-8b9a-0e7c9ab48297_1500x500.png"
      ],
      "title": "Why We Love Bright Moments: Tokyo Edition (Parte 1)"
    },
    {
      "_id": "647facf1707089084f8ba72c",
      "description": "En la última edición de Bright Moments, se mintearon cuatro diversas colecciones: \"CryptoTokyoites\", \"Tokyo Collection\", \"AI Art Collection\" y \"Japanese Contemporary Collection\".    \n\nhttps://jakalabs.substack.com/p/why-we-love-bright-moments-tokyo-63f",
      "images": [
        "https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fd983be84-1697-44de-b803-68ce7584a98b_1500x500.png"
      ],
      "title": " Why We Love Bright Moments: Tokyo Edition (Parte 2)"
    },
    {
      "_id": "64d129a5c275e12957277327",
      "description": "FUTURX es más que una comunidad; es un colectivo de aprendizaje que se encuentra en la intersección de la música y la tecnología, con un enfoque especial en la web3. Fundada y liderada por la empresa de música argentina 432 Hertzios, FUTURX tiene una misión clara: facilitar la adopción de nuevas tecnologías en los ecosistemas musicales de Latinoamérica.\nhttps://jakalabs.substack.com/p/futurx",
      "images": [
        "https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F9bdf4d79-da36-4dcd-a941-e1313ba3b506_6912x3456.png"
      ],
      "title": "FUTURX : Innovación y Aprendizaje en la Intersección de la Música y la Tecnología"
    }
  ]
  data = data.reverse();
  let [feeds, setFeeds] = useState([]);
  const [currFeed, setCurrFeed] = useState([]);
  const [showContainer, setShowContainer] = useState(false);
  const [currId, setCurrId] = useState('');
  const [isHovered, setIsHovered] = useState(false);


  useEffect(() => {

    setShowContainer(false);
    getFeeds();
  }, []);


  async function getFeeds() {
    setFeeds(data);
  };

  const getSingleFeed = (id) => {
    return data.find(item => item._id === id);
  }


  async function handleClick(id) {
    setShowContainer(showContainer === false ? true : false);
    const data = await getSingleFeed(id);
    const newId = data._id.$oid;
    setCurrFeed(data);
    setCurrId(id); // Ya no necesitamos extraer el $oid porque trabajamos directamente con el id
  }


  return (
    <>
      <Div>
        <Banner content={<div className="title">FEED</div>} />
      </Div>
      <br /><br />
      {
        showContainer ?
          (<div>

            <Container>
              <Row>
                <Col xs={12} md={8} className="mx-auto">

                  <button
                    onClick={() => setShowContainer(false)}
                    className="bg-transparent text-black mr-4 md:mr-10"
                  >
                    <FaArrowLeft size={32} />
                  </button> <br />
                </Col>
              </Row>
            </Container>

            <Feed data={currFeed} currId={currId} />
          </div>)
          : (
            <div className="mb-6">
              <Container>
                <Row>
                  {feeds.map((user) => (
                    <Col md={4} key={user._id} className="p-2 d-flex">
                      <Card
                        className="w-100 h-100"
                        onClick={() => handleClick(user._id)}
                        style={{
                          cursor: 'pointer',
                          boxShadow: isHovered ? "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" : "none",
                          transition: "box-shadow .3s ease-in-out"
                        }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <Card.Body>
                          <Card.Title className="text-center">{user.title}</Card.Title>
                          <Card.Text>
                            {user.description.length > 100
                              ? user.description.substring(0, 100) + "..."
                              : user.description}
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-center">
                          <Button variant="outline-dark" size="lg">
                            
                          </Button>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>

            </div>
          )
      }

    </>

  );
};

export default FeedsPage;
