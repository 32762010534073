import React from 'react';
import HomeVideo from '../../assets/video/home_video2.mp4';
import { Div } from './styles';

function Hero(props) {


  return (
    <Div>

      {props.status ?       
      <video
        autoPlay={true}
        controls={false}
        controlsList="nodownload nofullscreen noremoteplayback"
        loop={true}
        muted
        playsInline={true}
        style={{ pointerEvents: 'none' }}
        width="100%">
        <source src={HomeVideo} type="video/mp4" />
      </video> : <video
        autoPlay={true}
        controls={false}
        controlsList="nodownload nofullscreen noremoteplayback"
        loop={true}
        muted
        playsInline={true}
        ref={props.videoRef}
        style={{ pointerEvents: 'none' }}
        width="100%">
        <source src={HomeVideo} type="video/mp4" />
      </video>}

     
    </Div>
  );
}

export default Hero;
