import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { Div, List } from './styles';
import iconWallet from '../../../../images/ico-wallet.png';

function SideMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <MenuIcon />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          disablePadding: true,
        }}>
        <List>
          <Link to="/portfolio">
            <MenuItem onClick={handleClose}>Portfolio</MenuItem>
          </Link>

          <a href="http://labs.jaka.art/"  rel="noopener noreferrer">
            <MenuItem onClick={handleClose}>Labs</MenuItem>
          </a>

          <Link to="/collection">
            <MenuItem onClick={handleClose}>Collection</MenuItem>
          </Link>

          <Link to="/feed">
            <MenuItem onClick={handleClose}>Feed</MenuItem>
          </Link>

          <Link to="/bio">
            <MenuItem onClick={handleClose}>Bio</MenuItem>
          </Link>

          <a href="https://www.youtube.com/@damodajaka" target="_blank" rel="noopener noreferrer">
            <MenuItem onClick={handleClose}>DaModaJaka</MenuItem>
          </a> 

          <Link to="/contact">
            <MenuItem onClick={handleClose}>Contact</MenuItem>
          </Link>

          <Link to="/web3">
            <MenuItem onClick={handleClose}>
             
              <img src={iconWallet} alt="Wallet Icon" style={{ marginRight: '10px' }}/>
              Connect Wallet
            </MenuItem>
          </Link>


        </List>
      </Menu>
    </Div>
  );
}

export default SideMenu;
