import React from 'react';
import PropTypes from 'prop-types';
import { Container, LogoContainer, Text } from './styles';

function LinkCard({ text, image, bgColor, columns = 3, url = '' }) {
  return (
    <Container href={url} columns={columns} rel="noreferrer" target="_blank">
      <LogoContainer bgColor={bgColor}>
        <div>
          <img alt="card" src={image} />
        </div>
      </LogoContainer>

      <Text>{text}</Text>
    </Container>
  );
}

LinkCard.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
};

LinkCard.defaultProps = {
  text: 'texto',
  image: 'https://via.placeholder.com/150x50',
};

export default LinkCard;
