import styled from 'styled-components';
import { mediaQuery } from '../../helpers/responsive';

export const Container = styled.a`
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  width: 100%;

  @media (min-width: 576px) {
    width: calc(100% - 20px);
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    width: calc(50% - 20px);
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    width: ${(props) => `calc((100% / ${props.columns}) - 20px)`};
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 243px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#EDEDED')};
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: grayscale(1);
  transition: ease 0.15s;
  &:hover {
    filter: grayscale(0);
  }
  & img {
    padding: 30px;
    width: 100%;
  }
`;

export const Text = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: center;
  ${mediaQuery} {
    margin-bottom: 10px;
  }
`;
