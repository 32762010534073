import styled from 'styled-components';

export const BigTitle = styled.div`
  text-align: center;
  font-family: 'Bw Seido Round';
  font-size: 48px;
  margin-bottom: -1.7rem;
  text-transform: uppercase;

  @media (min-width: 576px) {
    font-size: 48px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 72px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    font-size: 140px;
  }
`;

export const Subtitle = styled.div`
  text-align: center;
  font-family: 'Bw Seido Round Light';
  font-size: 32px;
  margin-bottom: 20px !important;

  @media (min-width: 576px) {
    font-size: 32px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 38px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    font-size: 48px;
  }
`;

export const Logo = styled.div`
  & img {
    width: 100px;
    height: 72px;

    @media (min-width: 576px) {
      width: 120px;
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
      width: 140px;
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      width: 150px;
    }
  }
`;

export const Line = styled.div`
  width: 2px;
  height: 60px;
  background-color: #000;
  margin: 0 10px;

  @media (min-width: 576px) {
    margin: 0 20px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    margin: 0 30px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    margin: 0 40px;
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: bold;
  }

  div {
    align-items: center;
    display: flex; 
    gap: 6px;
  }
`;
