import PropTypes from 'prop-types';
import React from 'react';
import Navbar from './privateComponents/Navbar';
import Footer from './privateComponents/Footer';
import { Content, Root } from './styles';

function Layout({ children }) {
  return (
    <Root>
      <Navbar />

      <Content>{children}</Content>

      <Footer />
    </Root>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
