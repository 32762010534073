import PropTypes from 'prop-types';
import React from 'react';
import NavbarOG from './privateComponents/NavbarOG';
import Footer from './privateComponents/Footer';
import { Content, Root } from './styles';

function Layout({ children }) {

    return (
      <Root>
        <NavbarOG />
        <Content>{children}</Content>
        <Footer />
      </Root>
      );
}

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
