
import CssBaseline from '@mui/material/CssBaseline';
import  React, { useEffect, Fragment }  from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';
import LayoutFintechCreator from './components/LayoutFintechCreator';
import PortfolioDetail from './components/PortfolioDetail';
import FeedsPage from './containers/Feeds';
import HomePage from './containers/Home';
import Bio from './containers/Bio';
import Collection from './containers/Collection';
import Contact from './containers/Contact';
import Portfolio from './containers/Portfolio';
import MapaWeb3 from './containers/MapaWeb3';
import Test from './containers/Test';
import FintechCreatorHome from './containers/FintechCreatorHome';
import Web3 from './containers/Web3';
import Tensegrity from './containers/Tensegrity';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { initializeGA, logPageView }  from './components/GoogleAnalytics';

// Initialize Google Analytics
// ReactGA.initialize(Config.ga.trackingId);

function App() {

  // const location = useLocation();

  // useEffect(() => {
  //     initializeGA();
  // }, []);

  // useEffect(() => {
  //     logPageView();
  // }, [location]);

  return (
    <Fragment>
      <CssBaseline />
      <Layout>
        <Routes>

          <Route path="/" element={<HomePage />} />

          <Route path="/feed" element={<FeedsPage />} />

          <Route path="/portfolio" element={<Portfolio />} />

          <Route path="/portfolio-detail/:id" element={<PortfolioDetail />} />

          <Route path="/collection" element={<Collection />} />

          <Route path="/bio" element={<Bio />} />

          <Route path="/contact" element={<Contact />} />

          {/* <Route path="/jakalabs" element={<JakaLabs />} /> */}

          <Route path="/mapaweb3" element={<MapaWeb3 />} />

          <Route path="/web3" element={<Web3 />} />

          {/* <Route path="/labs" element={<Labs />} /> */}

          {/* <Route path="/web3latam" element={<MapWeb3 />} /> */}

          <Route path="/fintech-creator" element={<FintechCreatorHome />} />

          <Route path="/web3" element={<Web3 />} />

          <Route path="/tensegrity" element={<Tensegrity />} />

          {/* <Route path="/test" element={<Test />} /> */}

        </Routes>
      </Layout>


    </Fragment>
  );
}

export default App;
