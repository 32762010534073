import { Container } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import ReactDOM from "react-dom";
import Button from 'react-bootstrap/Button';
import Hero from '../../components/Hero';
import trustCode from '../../images/gifs/Home_trust_code.gif';
import { Intro, Div } from './styles';
import HomeAudio from '../../assets/audios/audiojaka.mp3';
import playIcon from '../../images/play-circle-solid.svg';
import stopIcon from '../../images/stop-circle-regular.svg';

function HomePage() {


  const buttonRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef(null);

  function togglePlay() {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);

    videoRef.current.currentTime = 0;
    videoRef.current.play();

    ReactGA.event({
      category: 'Home',
      action: 'Play Audio',
      label: 'home play audio',
    });

  }

  useEffect(() => {
    audioRef.current.play();
  }, []);

  const videoRef = useRef(null);

  return (
    <div style={{ maxWidth: '100%' }}>
      <div onClick={togglePlay} ref={buttonRef} >
        <Hero status={false} videoRef={videoRef} />
      </div>

      <audio ref={audioRef} src={HomeAudio} loop />

      <div style={{ textAlign: 'center' }}>
        <br />
        <Button variant="white" onClick={togglePlay} ref={buttonRef}>
          {isPlaying ? <img src={stopIcon} alt="logo" width="45px" height="45px" /> : <img src={playIcon} alt="logo" width="45px" height="45px" />}
        </Button>
      </div>




      <Container>
        <Intro>
          <div className="title">Creative Capital for Entrepreneurs</div>

          <div className="description">
            <p>
              JAKA fuels entrepreneurial endeavors by investing in founders building solutions in Fintech,
              Crypto/web3, Art and Media. Our custom, targeted approach combines artistic creativity with financial
              acumen and business track-record to help kickstart projects in idea and pre-seed stages.
            </p>

            <p>
              Our mission is to maximize positive impact by unlocking opportunities for creative realization.
              We think of money as a tool to achieve this, and use it as a form of energy and trust that flows through
              high impact value-creation ventures.  {' '}
            </p>

            <p>
              We partner with the next generation of founders, artists and other investors who embrace values such as kindness
              and ambition. If you believe that the combination of Art, Finance and Technology can help launch your creative project,
              we’d love to hear from you!
            </p>

          </div>
        </Intro>

        <img
          alt="trust"
          src={trustCode}
          style={{ maxWidth: '100%', marginBottom: '2rem' }}
        />
      </Container>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<HomePage />, rootElement);
export default HomePage;
