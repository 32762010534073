import { Image } from '@chakra-ui/react';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import jakaBlack from '../../../../images/jaka-black.svg';
import iconWallet from '../../../../images/ico-wallet.png';
import SideMenu from '../SideMenu';

function Navbar() {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={{ xs: 'space-between', lg: 'center' }}
      style={{ backgroundColor: 'black' }}
      py="10px"
      w="100%"
    >
      <Box></Box>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent={{ xs: 'center', lg: 'flex-start' }}
        spacing="25px"
        px={{ xs: 2, lg: 0 }}
      >
        <Box as={Link} to="/">
          <Image alt="logo" filter="invert(1)" src={jakaBlack} w="100px" />
        </Box>

        <Stack
          display={{ xs: 'none', lg: 'flex' }}
          direction="row"
          alignItems="center"
          spacing="25px"
        >
          <Typography
            as={Link}
            to="/portfolio"
            color="#fff"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase"
          >
            Portfolio
          </Typography>

          <Typography
            component="a"     // usa 'a' en lugar de 'Link'
            href="http://labs.jaka.art/"  // agrega esto
            color="#fff"
            rel="noopener noreferrer"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase"
          >
            Labs
          </Typography>

          <Typography
            as={Link}
            to="/collection"
            color="#fff"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase"
          >
            Collection
          </Typography>

          <Typography
            as={Link}
            to="/feed"
            color="#fff"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase"
          >
            Feed
          </Typography>



          <Typography
            as={Link}
            to="/bio"
            color="#fff"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase"
          >
            Bio
          </Typography>

          <Typography
            component="a"     
            href="https://www.youtube.com/@damodajaka"  
            color="#fff"
            target="_blank"
            rel="noopener noreferrer"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase"
          >
            DaModaJaka
          </Typography>


          <Typography
            as={Link}
            to="/contact"
            color="#fff"
            fontFamily="Bw Seido Round"
            style={{ textDecoration: 'none' }}
            textTransform="uppercase"
          >
            Contact
          </Typography>

          {/* New Icon and Text */}
          <Box display="flex" alignItems="center">
            <Image src={iconWallet} alt="Wallet Icon" w="20px" h="auto" />
            <Typography
              as={Link}
              to="/web3"
              color="#fff"
              fontFamily="Bw Seido Round"
              style={{ textDecoration: 'none', marginLeft: '8px' }}
              textTransform="uppercase"
            >
              Connect Wallet
            </Typography>
          </Box>
          {/* End of New Icon and Text */}
        </Stack>
      </Stack>

      <Box display={{ xs: 'block', lg: 'none' }}>
        <SideMenu />
      </Box>
    </Stack>
  );
}

export default Navbar;